import React from "react";
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
// import FooterLinks from './footer-links/footer-links.js'
import HelpAndCurrency from './help-and-currency.js';
import AboutLinks from './footer-links/about-links.js';
import * as Utilities from './utilities.js';

export default class ContactUs  extends React.Component {
	
  constructor() {
    super();
    this.state = {
      email: null,
	  name: null,
	  phone: null,
	  message: null,
	  captcha: null,
	  code: null,
	  pageReturn: null,
    };
	this.handleInputChange = this.handleInputChange.bind(this);

  }
  componentDidMount() {
	var ga = Utilities.gaInitialize(ReactGA);
	Utilities.gaPageView(ga, "page_view", "/contact", "Contact Us");
	var url = Utilities.getApiURL('captcha.php', '');
    fetch(url, {method: 'GET', credentials: 'include'})
    .then(results => {
      return results.json();
    }).then((data) => {
		this.setCaptcha(data);
    })
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  sendMessage(){
  	var url = Utilities.getApiURL('contact.php', '');
	var body = "&name="+this.state.name
					+"&email="+this.state.email
					+"&phone="+this.state.phone
					+"&message="+this.state.message;
    fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: body
		})
    .then(results => {
      return results.json();
    }).then((data) => {
        this.alertUser(data);
    })
  }
  alertUser(data){
	  if(data.response)
		  alert(data.response.message);
	  else
		  alert("Error: "+data.error.message);
  }
  captcha(){
  	  var url = Utilities.getApiURL('captcha.php', '?do=check&code='+this.state.code);
	  var body = "&code="+this.state.code;
      fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: body
		})
      .then(results => {
      return results.json();
      }).then((data) => {
        this.captchaResult(data);
      })
  }
  setCaptcha(data) {
	let captcha = data.captcha.clue.map((c) => {
		return (<img src={c}/>)
	});
	this.setState({captcha: captcha});
  }
  captchaResult(data){
	  if(data.captcha.status === true)
		  this.sendMessage()
	  else {
		  alert("Error: Verification code did not match.  Please check the code and try again.")
		  this.setCaptcha(data);
	  }

  }
  render(){

    return(
      <div>

        <div className = 'content-area-container'>
          <div className="contact-header-text">

          </div>
          <div className="phone-container">
            <div className="telephone-header">
              <h1 className="telephone">Telephone & Hours of Operation</h1>
            </div>
            <div className="phone-details">
              <h4 className="hours">Monday - Friday 8:00am - 5:00pm EST</h4>
              <h4 className="toll-free">Toll Free: (877) 672-4799</h4>
              <h4 className="local-phone">Local: (631) 331-2740</h4>
              <h4 className="fax">Fax: (631) 331-2947</h4>
            </div>
          </div>
        <div className="mailing-container">
          <div className="mailing-address-header">
            <h1 className="address">Mailing Address</h1>
          </div>
          <div className="address-details">
            <h4 className="address1">MS Air, Inc.</h4>
            <h4 className="address2">64 Mt. Sinai Avenue</h4>
            <h4 className="address3">Mt. Sinai, NY 11766</h4>
          </div>
        </div>
		
        <div className="email-container">
          <div className="email-form-header">
            <h1 className="email-header">Contact Us</h1>
          </div>
          <div className="email-details">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="inputName"><strong>* Name: </strong></label>
                  <input type="text" name="name" className="form-control" placeholder="Name" onChange={this.handleInputChange}></input>
                </div>
                <div className="form-group col-md-6">
                  <label className="inputEmail4"><strong>* Email: </strong></label>
                  <input type="email" name="email" className="form-control" id="inputEmail4" placeholder="Email" onChange={this.handleInputChange}/>
                </div>
                <div className="form-group col-md-6">
                  <label className="inputPhone"><strong>Phone:</strong></label>
                  <input type="tel" name="phone" className="form-control" id="inputPhone" placeholder="123-456-7890" onChange={this.handleInputChange}/>
                </div>
                <div className="form group col-md-6">
                  <label className="inputMessage"><strong>Message:</strong></label>
                  <textarea name="message" className="form-control" id="inputMessage" placeholder="Message" rows="8" cols="50"  onChange={this.handleInputChange}/>
                </div>
                </div>
                <br />
                <div className="email-disclaimer">
                  <p>Please Note:</p>
                  <ul>
                    <li>MS Air will not share your contact information with any other party.</li>
                    <li>MS Air will not store your information. We will only use it to help answer your questions.</li>
                    <li>You must provide either a phone number or email address so that we can respond to your inquiry.</li>
                    <li>The security code is required so that we only receive valid questions from customers like you.</li>
                  </ul>
                </div>

                <div className="accountFormText">*Enter Code:</div>
		              <div className="accountForm">
					  {this.state.captcha}
                    <input type="text" name='code' className="code" size="5" maxLength="3" onChange={this.handleInputChange}></input>
                  </div>

                <div className="contact-button">
                  <button type="submit" className="btn btn-primary" onClick={(e) => this.captcha()}><h4>Submit</h4></button>
                </div>

          </div>
        </div>
		

        <div className="FooterLinks1">
          <HelpAndCurrency />

        </div>
        <div className="FooterLinks2">
          <AboutLinks />
        </div>
      </div>
              <div className="about-footer">
                <div className="terms">
                  <p><a href="/terms" rel="noopener noreferrer">Terms of Use</a> | <a href="/privacy" rel="noopener noreferrer">Privacy Policy</a></p>
                </div>
                <div className="copyright">
                  <p>&copy; 2018 - MS Air, Inc. | <Link to="/">Home</Link></p>
              </div>
              </div>

      </div>


    )
  }
}
