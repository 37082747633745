import React from "react";
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
import HelpAndCurrency from './help-and-currency.js';
import AboutLinks from './footer-links/about-links.js';
import * as Utilities from './utilities.js';


export default class Checkout1 extends React.Component {
  constructor() {
    super()
    this.state = {
	  first: "",
      last: "",
	  company: "",
      email: "",
      address: "",
      address2: null,
	  address2_linebreak: null,
      city: "",
      state: "AL",
      zip: "",
	  ups_address: "",
      ups_city: "",
      ups_state: "AL",
      ups_zipcode: "",
      shipping: null,
	  tax_exempt_input: null,
	  states: ['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
    };

  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
	if(this.state.address2 != '')
		this.setState({address2_linebreak: "<br>"}); 
  }
  componentDidMount() {
	var ga = Utilities.gaInitialize(ReactGA);
	Utilities.gaPageView(ga, "page_view", "/checkout1", "Checkout 1");
	var url = Utilities.getApiURL('checkout.php', '');
    fetch(url, {method: 'GET', credentials: 'include'})
    .then(results => {
      return results.json();
    }).then((data) => {
        if(data.is_logged_in === false){
			window.location = 'login?pageReturn=viewcart'
		} else {
			this.populateForm(data.shipping)
		}
    })
  } 
  next(){
 	var url = Utilities.getApiURL('checkout.php', '?do=setShippingAddress');
	var body = "&first="+this.state.first
					+"&last="+this.state.last
					+"&email="+this.state.email
					+"&company="+this.state.company
					+"&street1="+this.state.address
					+"&street2="+this.state.address2
					+"&city="+this.state.city
					+"&state="+this.state.state
					+"&zip="+this.state.zip
					+"&phone1="+this.state.phone1
					+"&phone2="+this.state.phone2
					+"&phone3="+this.state.phone3
					+"&tax_exempt="+this.state.tax_exempt;
    fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: body
		})
    .then(results => {
      return results.json();
    }).then((data) => {
        this.confirmUpdate(data);
    })
  }
  keep_address(){
	//alert("Proceed to next checkout page.");
	window.location = 'checkout2';
  } 
  set_suggested_address(){
	var url = Utilities.getApiURL('checkout.php', '?do=setSuggestedAddress');
	var body = "&first="+this.state.first
					+"&last="+this.state.last
					+"&email="+this.state.email
					+"&company="+this.state.company
					+"&street1="+this.state.ups_address
					+"&street2="+this.state.address2
					+"&city="+this.state.ups_city
					+"&state="+this.state.ups_state
					+"&zip="+this.state.ups_zipcode
					+"&phone1="+this.state.phone1
					+"&phone2="+this.state.phone2
					+"&phone3="+this.state.phone3
					+"&tax_exempt="+this.state.tax_exempt;
    fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: body
		})
    .then(results => {
      return results.json();
    }).then((data) => {
        this.confirmUpdate(data);
    })
  }
  close_modal(){
	  const modal = document.getElementById("myModal");
	  modal.style.display = "none";
  }
  confirmUpdate(data){
	  if(data.error) {
		  alert("Error: "+data.error.message);
	  }
	  else if (data.warning) {
		  this.showAddressVerificationPrompt(data);
	  }
	  else {
		  //alert("Address passed verification.");
		  window.location = 'checkout2';
	  }
  }


  showAddressVerificationPrompt(d){
	// Get the modal
	const modal = document.getElementById("myModal");

	// Get the <span> element that closes the modal
	const span = document.getElementsByClassName("close")[0];
	this.setState({ups_address: d.verify.XAVResponse.Candidate[0].AddressKeyFormat.AddressLine[0]});
	this.setState({ups_city: d.verify.XAVResponse.Candidate[0].AddressKeyFormat.PoliticalDivision2});
	this.setState({ups_state: d.verify.XAVResponse.Candidate[0].AddressKeyFormat.PoliticalDivision1});
	this.setState({ups_zipcode: d.verify.XAVResponse.Candidate[0].AddressKeyFormat.PostcodePrimaryLow});
	
	var ups_full_address = this.state.ups_address+this.state.ups_city+this.state.ups_state+this.state.ups_zipcode;
	ups_full_address = ups_full_address.toLowerCase();
	var user_full_address = this.state.address+this.state.city+this.state.state+this.state.zip;
	user_full_address = user_full_address.toLowerCase();
	
	if(ups_full_address != user_full_address){
		modal.style.display = "block";
	}else {
		//alert("Addresses match.");
		window.location = 'checkout2';
	}
		

	// When the user clicks on <span> (x), close the modal
	span.onclick = function() {
	  modal.style.display = "none";
	}

	// When the user clicks anywhere outside of the modal, close it
	window.onclick = function(event) {
	  if (event.target == modal) {
		modal.style.display = "none";
	  }
	}	  
  }
  getTaxExemptInput(a) {
	  	if(a.tax_exempt === "true") {
			//alert("Tax Exempt: TRUE|"+a.tax_exempt);
			return(<input name='tax_exempt' className="form-check-input" defaultChecked="true" type="checkbox" onChange={this.handleChange.bind(this)} id="tax_exempt" />);
		} else {
			//alert("Tax Exempt: FALSE|"+a.tax_exempt);
			return(<input name='tax_exempt' className="form-check-input" type="checkbox" onChange={this.handleChange.bind(this)} id="tax_exempt" />);
		}
  }
  populateForm(a){
	var states = this.state.states.map((state) =>{
		if(state===a.state)
			return(<option selected='selected'>{state}</option>);
		else
			return(<option>{state}</option>);
	});
	var tax_exempt_input = this.getTaxExemptInput(a);
	
		this.setState({first: a.first});
		this.setState({last: a.last});
		this.setState({email: a.email});
		this.setState({company: a.company});
		this.setState({address: a.address});
		this.setState({address2: a.address2});
		this.setState({city: a.city});
		this.setState({state: a.state});
		this.setState({zip: a.zip});
		this.setState({phone1: a.phone_parts[0]});
		this.setState({phone2: a.phone_parts[1]});
		this.setState({phone3: a.phone_parts[2]});
		if(a.address2 != '')
			this.setState({address2_linebreak: "<br>"}); 

	  var shipping = (
	  <fieldset>
			<div className="form-row">
                <div className="form-group col-md-6">
                  <label className="inputFirstName"><strong>* First Name</strong></label>
                  <input type="text" name="first" onChange={this.handleChange.bind(this)} className="form-control" id="inputFirstName" defaultValue={a.first} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputLastName"><strong>* Last Name</strong></label>
                  <input type="text" name="last" onChange={this.handleChange.bind(this)} className="form-control" id="inputLastName" defaultValue={a.last} />
                </div>
              </div>


              <div className="form-group col-md-6">
                <label className="inputAddress"><strong>* Address</strong></label>
                <input type="text" name="address" onChange={this.handleChange.bind(this)} className="form-control" id="inputAddress" defaultValue={a.address} />
              </div>

              <div className="form-group col-md-6">
                <label className="inputAddress2"><strong>* Address 2</strong></label>
                <input type="text" name="address2" onChange={this.handleChange.bind(this)} className="form-control" id="inputAddress2" defaultValue={a.address2} />
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="inputCity"><strong>* City</strong></label>
                  <input type="text" name="city" onChange={this.handleChange.bind(this)} className="form-control" id="inputCity" defaultValue={a.city} />
                </div>

                <div className="form-group col-md-4">
                  <label className="inputState"><strong>* State</strong></label>
                  <select type="text" name="state" onChange={this.handleChange.bind(this)} id="inputState" className="form-control">
				  {states}
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <label className="inputZip"><strong>* Zip</strong></label>
                  <input type="text" name="zip" onChange={this.handleChange.bind(this)} className="form-control" id="inputZip" defaultValue={a.zip} />
              </div>

			  <div className="form-row">
                <div className="form-group col-md-4">
                  <label className="inputPhone"><strong>* Phone</strong></label>
                    <input name="phone1" type="text" onChange={this.handleChange.bind(this)} className="form-control" id="inputPhone1" defaultValue={this.state.phone1}/>
                </div>
                <div className="form-group col-md-4">
                  <label className="inputPhone2">Phone 2</label>
				              <input name="phone2" type="text" onChange={this.handleChange.bind(this)} className="form-control" id="inputPhone2" defaultValue={this.state.phone2}/>
                </div>
                <div className="form-group col-md-4">
                  <label className="inputPhone3"><strong>Phone 3</strong></label>
                    <input name="phone3" type="text" onChange={this.handleChange.bind(this)} className="form-control" id="inputPhone3" defaultValue={this.state.phone3}/>
                </div>
              </div>

			<div className="terms-and-conditions-checkbox">
				<div className="form-check">
				{tax_exempt_input}
                    <label className="form-check-label">
                      <p>Check here if this is a tax exempt order. New customers will be contacted by email to request your tax exempt certificate.</p>
                    </label>
				</div>
			  </div>
            </div>


          </fieldset>);
			this.setState({shipping: shipping});
  }


  render(){

    return(

      <div>

        <div className = 'content-area-container'>
          <div className="checkout-header-image">
            <img src='https://www.msaironline.com/images/purchase01.gif' alt='Purchase Information' />
          </div>

          <div className="replacement_parts_header">
            <h1>Shipping Address</h1>
          </div>


			  {this.state.shipping}




        <div className="form-group" id="checkout1-next-button">
           <button type="submit" className="btn btn-primary" onClick={(e) => this.next()}><h4>Next</h4></button>
        </div>








        <div className="FooterLinks1">
          <HelpAndCurrency />
        </div>

        <div className="FooterLinks2">
          <AboutLinks />
        </div>

      </div>

        <div className="about-footer">
          <div className="terms">
            <p><a href="/terms" rel="noopener noreferrer">Terms of Use</a> | <a href="/privacy" rel="noopener noreferrer">Privacy Policy</a></p>
          </div>
          <div className="copyright">
            <p>&copy; 2018 - MS Air, Inc. | <Link to="/">Home</Link></p>
          </div>
        </div>

<dialog  id="myModal" class="modal">
  <div class="modal-content">
  
    <span class="close">&times;</span>
	<h1>Address Verification</h1>
	<p>UPS has suggested an alternative address. Please choose the best option below or close the window to edit the shipping address.</p>
	
	<h3 className="modal-heading">Your address:</h3>
	<div className="modal-body">
		<p>{this.state.address}<br/>
			{this.state.address2}{this.state.address2_linebreak}
			{this.state.city}, {this.state.state}, {this.state.zip}</p>
		<p><button onClick={(e) => this.keep_address()}>Keep Address</button></p>
	</div>
	
	<h3 className="modal-heading">UPS Suggested Address:</h3>
	<div className="modal-body">
		<p>{this.state.ups_address}<br/>
			{this.state.address2}{this.state.address2_linebreak}
			{this.state.ups_city}, {this.state.ups_state}, {this.state.ups_zipcode}</p>
		<p><button onClick={(e) => this.set_suggested_address()}>Use This Address</button></p>
	</div>
	
	
	<h3 className="modal-heading">Close Window</h3>
	<div className="modal-body">
		<p>Return to the shipping address form and edit the address.</p>
		<p><button onClick={(e) => this.close_modal()}>Edit Address</button></p>
	</div>
	
  </div>
</dialog>

      </div>




    )
  }
}
